import React from "react"
import Slider from "react-slick"
import LazyImage from "./LazyImage"
import Low from "../images/scrapbookLow.png"
import Scrapbook2 from "../images/scrapbook1.png"
import Scrapbook3 from "../images/slide3.png"
// import Scrapbook4 from "../images/slide2.png"
import Scrapbook1 from "../images/slide1.png"
import { Link } from "gatsby"

var settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  adaptiveHeight: true,
  arrows: true,
  fade: false,
  autoplaySpeed: 5000,
}

export default function HomeCarousel() {
  return (
    <div style={{ position: "relative" }}>
      <Slider {...settings}>
        <div className="caro gradient-1 mob-height">
          <div className="container text-center align-items-center">
            <div className="m-top-carousel" />

            <div className="row mt-5 align-items-center justify-content-center">
              <div className="col-lg-6 my-2">
                <LazyImage
                  className="mob-scale"
                  lowRes={Low}
                  highRes={Scrapbook1}
                  width="100%"
                />
              </div>
              <div className="col-lg-5 my-2 pb-5">
                <div style={{ position: "relative" }} className="mob-scale">
                  <div className="torn-card ">
                    <div className="px-4 py-5 bg-white h-100  row justify-content-center text-center">
                      <h1 className="secret">Valentines Day Special Package</h1>
                      <p>Get free shipping</p>
                      <div className="col-sm-6 my-2 mt-4">
                        <Link
                          to="/product/FWvPqyWawGBZ2O5B6RD2"
                          class="btn btn-primary w-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-plus-circle"
                            viewBox="0 0 18 18"
                          >
                            <path d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3z" />
                          </svg>
                          &nbsp;&nbsp;Pre Order Now
                        </Link>
                      </div>
                    </div>
                    <div className="top-sticky" />
                    <div className="bottom-sticky" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="caro gradient-2 mob-height">
          <div className="container text-center align-items-center">
            <div className="m-top-carousel" />
            <div className="row mt-5 align-items-center justify-content-center">
              <div className="col-md-6 my-2">
                <LazyImage
                  className="mob-scale"
                  lowRes={Low}
                  highRes={Scrapbook2}
                  width="100%"
                />
              </div>
              <div className="col-md-5 my-2">
                <div style={{ position: "relative" }} className="mob-scale">
                  <div className="torn-card ">
                    <div className="px-4 py-5 bg-white h-100  row justify-content-center text-center">
                      <h1 className="secret">
                        A perfect Scrapbook for your valentine
                      </h1>
                      <p>Starting from $60</p>
                      <div className="col-sm-6 my-2 mt-4">
                        <Link
                          to="/product/ymnvCtPLp5ww90eC0gt6"
                          class="btn btn-primary w-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-plus-circle"
                            viewBox="0 0 18 18"
                          >
                            <path d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3z" />
                          </svg>
                          &nbsp;&nbsp;Pre Order Now
                        </Link>
                      </div>
                    </div>
                    <div className="top-sticky" />
                    <div className="bottom-sticky" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="caro gradient-3 mob-height">
          <div className="container text-center align-items-center">
            <div className="m-top-carousel" />
            <div className="row mt-5 align-items-center justify-content-center">
              <div className="col-md-6 my-2">
                <LazyImage
                  className="mob-scale"
                  lowRes={Low}
                  highRes={Scrapbook3}
                  width="100%"
                />
              </div>
              <div className="col-md-5 my-2">
                <div style={{ position: "relative" }} className="mob-scale">
                  <div className="torn-card ">
                    <div className="px-4 py-5 bg-white h-100  row justify-content-center text-center">
                      <h1 className="secret">
                        Wine bottle with personalized scroll
                      </h1>
                      <p>Starting from $25.2</p>
                      <div className="col-sm-6 my-2 mt-4">
                        <Link
                          to="/product/jGU555LkEOGR98IuWaEx"
                          class="btn btn-primary w-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-plus-circle"
                            viewBox="0 0 18 18"
                          >
                            <path d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3z" />
                          </svg>
                          &nbsp;&nbsp;Pre Order Now
                        </Link>
                      </div>
                    </div>
                    <div className="top-sticky" />
                    <div className="bottom-sticky" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="caro gradient-3">
          <div className="container text-center align-items-center">
            <h1 className="secret mt-5" style={{ opacity: 0.8 }}>
              Pre-Order By January 30th
            </h1>
          </div>
        </div> */}
      </Slider>
      <h1
        className="secret mt-5 text-center w-100 order-title"
        style={{ position: "absolute", top: 0, opacity: 0.8 }}
      ></h1>
      <div className="caro-slider"></div>
    </div>
  )
}
