import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import "../components/app.scss"
import LazyImage from "../components/LazyImage"
import Low from "../images/low_gradient.png"
// import icon from "../images/gatsby-icon.png"
import "../components/app.scss"
// import Countdown from "../components/Countdown"
import Navbar from "../components/Navbar"
import HomeCarousel from "../components/HomeCarousel"
import Footer from "../components/Footer"

import Banner from "../images/coming_banner.png"
import BannerLow from "../images/coming_banner_low.png"

const IndexPage = props => {
  const [products, setProducts] = useState([])
  const [category, setCategory] = useState("ALL")

  useEffect(() => {
    fetchProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchProducts = async () => {
    const response = props.data.allProduct.nodes

    setProducts(response)
  }

  const sortProducts = data => {
    if (category === "ALL") {
      return data
    } else if (category === "SCRAPBOOKS") {
      return data.filter(
        word => word.category === "SCRAPBOOK" || word.category === "CARD"
      )
    } else {
      return data.filter(
        word => word.category !== "SCRAPBOOK" && word.category !== "CARD"
      )
    }
  }

  return (
    <div
      id="pre-order"
      style={{ maxWidth: "100%", width: "100%", overflowX: "hidden" }}
    >
      <SEO title="Home" />
      {/* <LazyImage lowRes={icon} highRes={logo} alt="Cupidscone" width="350" />
    <h2>Coming Soon</h2>
    <br />
    <p style={{ fontSize: "20px" }}>
      <Countdown />
    </p> */}
      <Navbar location={props.location} />
      <div style={{ marginTop: "9rem" }} />

      <HomeCarousel />
      <div id="products" />

      <div className="container text-center" style={{ marginTop: "10rem" }}>
        <h1 className="secret mt-5">Latest Products</h1>
        <ul className="nav nav-pills justify-content-center mt-5">
          <li className="nav-item">
            <button
              className={
                category === "ALL"
                  ? "btn btn-primary px-4 mx-2 lato"
                  : "btn btn-flat px-4 mx-2 lato"
              }
              onClick={() => setCategory("ALL")}
            >
              All Products
            </button>
          </li>
          <li className="nav-item">
            <button
              className={
                category === "SCRAPBOOKS"
                  ? "btn btn-primary px-4 mx-2 lato"
                  : "btn btn-flat px-4 mx-2 lato"
              }
              onClick={() => setCategory("SCRAPBOOKS")}
            >
              Scrapbooks & Cards
            </button>
          </li>
          <li className="nav-item">
            <button
              className={
                category === "MISC"
                  ? "btn btn-primary px-4 mx-2 lato"
                  : "btn btn-flat px-4 mx-2 lato"
              }
              onClick={() => setCategory("MISC")}
            >
              Miscellaneous
            </button>
          </li>
        </ul>
        <div className="row mt-4">
          {sortProducts(products).map((el, index) => (
            <Link
              key={el.id}
              to={"/product/" + el.id}
              className="card col-lg-4"
            >
              <div
                className="card-elements"
                style={{
                  borderImageSlice: `${Math.floor(Math.random() * 30) + 22}%`,
                }}
              >
                <LazyImage
                  lowRes={Low}
                  highRes={
                    el.images[0].type === "video"
                      ? el.images[1].url
                      : el.images[0].url
                  }
                  className="card-img-top"
                  alt="..."
                  style={{ height: "400px" }}
                />
                <div className="card-body">
                  <h4 className="card-text secret text-accent">
                    {el.name}
                    <p
                      className="card-text text-dark"
                      style={{ fontFamily: "Lato", fontSize: 16 }}
                    >
                      {el.category}
                      <br />
                      {el.price !== el.discount_price ? (
                        <strike className="text-danger mt-2">
                          ${el.price.toFixed(1)}
                        </strike>
                      ) : el.category === "COMBO" ? (
                        <p className="text-danger mt-2 mb-0">Free Shipping</p>
                      ) : (
                        ""
                      )}
                    </p>
                  </h4>
                </div>
                <div className="price-tag">${el.discount_price.toFixed(1)}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div id="coming-soon" />
      <div className="container text-center">
        <h1 className="secret" style={{ marginTop: "10rem" }}>
          Coming Soon
        </h1>
        <div style={{ marginTop: "2rem" }}>
          <LazyImage
            lowRes={BannerLow}
            highRes={Banner}
            style={{
              width: "100%",
              borderRadius: "20px",
              border: "6px solid rgba(255,255,255,0.3",
            }}
          />
        </div>
      </div>
      <div id="about-us" />
      <div className="container text-center mt-5">
        <h1 className="secret" style={{ marginTop: "10rem" }}>
          About us
        </h1>
        <div className="row justify-content-center">
          <div className="col-md-8" style={{ position: "relative" }}>
            <div className="torn-card ">
              <div className="px-4 py-5 bg-white">
                <p>
                  We are crafters. Cupidscone crafting TEAM have a combined
                  experience of 15 years. We are here to put a smile on the face
                  of your loved ones. Our TEAM is dedicated to custom craft a
                  gift which is meaningful and means more than just a gift. A
                  bigger smile on their face, a better expression of your heart-
                  creates an unforgettable moment of happiness. Thus, we also
                  call ourselves the crafters of happiness. Each of our
                  scrapbooks are handmade thinking of your precious ones!
                </p>
              </div>
            </div>
            <div className="top-sticky" />
            <div className="bottom-sticky" />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10rem" }} />
      <Footer />
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allProduct(sort: { fields: price, order: DESC }) {
      nodes {
        category
        description
        id
        images {
          type
          url
        }
        name
        price
        discount_price
        points
      }
    }
    allComingSoon(sort: { fields: image, order: ASC }) {
      nodes {
        id
        image
        name
      }
    }
  }
`
